/* User defined data
 *
 * Input user data here.
 * Ensure each item is defined, at least as an empty string.
 *
 */
import React from "react"
import styled, { keyframes } from "styled-components"
import { Breakpoints } from "./src/util/breakpoints"
import useSiteMetadata from "./src/hooks/use-site-metadata"
import useUserPreferences from "./src/hooks/use-user-preferences"

import TabLink from "./src/components/tab-link"
import ModalLink from "./src/components/modal-link"
import Modal from "./src/components/modal"
import CustomForm from "./src/components/custom-form"
import DisplayItemList from "./src/components/display-item-list"
import Youtube from "./src/components/youtube"

import DynamicImage from "./src/components/dynamic-image"
import SlideShow from "./src/components/slideshow"
import TabList from "./src/components/tab-list"
import Title from "./src/components/title"

/**********************************************************************
 * Input client website customization below
 **********************************************************************/

const footer = (<>
  <p>
    &copy; 2020&ndash;2021&nbsp;
    <a href="https://opensocialresources.com/" target="_blank" rel="noreferrer">
      Open Social Resources
    </a>. All rights reserved.
    Fontawesome icons are licensed under&nbsp;
    <a href="https://fontawesome.com/license" target="_blank" rel="noreferrer">
      CC-BY 4.0</a>. 
  </p>
</>)

/* Tab info and settings
 * Any tabs you want.
 *
 * For special tabs and components, you'll need to adjust imports in this file
 *
 * Use ProgressiveImage for images over img
 */
const featuresTab = {
  slug: "features",
  name: "Features",
  content: (<>
    <p>
      As a small business, we understand the importance of having an effective website.
      We also know how challenging it can be to set up a website while managing
      every other part of your business.
      By taking the technical and design burdens off your shoulders,
      the Website Template Project empowers small business owners like us to
      create professional websites.
      You determine what goes into your site as a business owner,
      and we'll handle the rest.
    </p>

    <h2>Features</h2>
    <DisplayItemList items={new Map([

      ["Showcase", new Map(Object.entries({
        primaryInfo: (<>
          <p>
            <i>Check out our client websites and demos.</i>
          </p>
        </>), image: "paintbrush.png",
        details: new Map(Object.entries({
          itemId: "website-showcase",
          detailsContent: (<>
            <DisplayItemList items={new Map([

              ["Website Template Project", new Map(Object.entries({
                primaryInfo: (<>
                  <p><i>
                    As a small business, our own website is a perfect
                    place to put our technology to use.
                  </i></p>
                </>), image: "showcase_wtp.jpg"
              }))],

              ["MHA General Trading", new Map(Object.entries({
                link: "https://mha-trade.com/",
                primaryInfo: (<>
                  <p><i>Trading Turkish and Lebanese products across the globe
                  </i></p>
                </>), image: "showcase_mha_general_trading.jpg",
              }))],

              ["Oak Park Muslim Community", new Map(Object.entries({
                link: "https://oakparkmuslims.org/",
                primaryInfo: (<>
                  <p><i>A local Muslim community
                    serving Oak Park, IL and the surrounding neighborhoods
                  </i></p>
                </>), image: "showcase_oak_park_muslims.jpg"
              }))],

              ["Dos Hermanos", new Map(Object.entries({
                link: "https://doshermanosypsi.com/",
                primaryInfo: (<>
                  <p><i>Authentic Mexican, Central, and South American Deli, Grocer, Bakery, and Meat Market
                  </i></p>
                </>), image: "showcase_dos_hermanos.jpg",
              }))],

              ["Ravensong Counseling", new Map(Object.entries({
                link: "https://ravensongcounseling.com/",
                primaryInfo: (<>
                  <p><i>Equine Assisted Counseling. Brilliant.
                  </i></p>
                </>), image: "showcase_ravensong_counseling.jpg"
              }))],

              ["Georgetown Carpet", new Map(Object.entries({
                link: "https://georgetowncarpet.com/",
                primaryInfo: (<>
                  <p><i>Carpets and flooring
                  </i></p>
                </>), image: "showcase_georgetown_carpet.jpg"
              }))],

              ["Safe Niche Science", new Map(Object.entries({
                link: "https://safenichescience.com/",
                primaryInfo: (<>
                  <p><i>Specialized lab equipment for safer anesthetics
                  </i></p>
                </>), image: "showcase_safe_niche_science.jpg"
              }))],

              ["Spoon in Hand", new Map(Object.entries({
                link: "https://website-template-project.gitlab.io/demo-spoon-in-hand/",
                primaryInfo: (<>
                  <p><i>A straightforward demo website
                  made for a brick-and-mortar restaurant.
                  It enables customers to easily check hours,
                  get directions, see the menu, contact the restaurant,
                  and share the website.
                  </i></p>
                </>), image: "showcase_spoon_in_hand.jpg"
              }))],

            ])} />
          </>)
        }))
      }))],

      ["Website Features", new Map(Object.entries({
        primaryInfo: (<>
          <p>
            <i>Our websites provide a unique set of features
          with quality your won't find anywhere else.</i>
          </p>
        </>), image: "wheelchair.png",
        details: new Map(Object.entries({
          itemId: "website-features",
          detailsContent: (<>
            <h2>Website features</h2>
            <DisplayItemList items={new Map([

              ["One small business to another", new Map(Object.entries({
                primaryInfo: (<ul>
                  <li>Straightforward process</li>
                  <li>Support for brick-and-mortar businesses</li>
                  <li>Helpful representatives</li>
                  <li>Affordable</li>
                </ul>), image: "partners.png"
              }))],

              ["Professional", new Map(Object.entries({
                primaryInfo: (<ul>
                  <li>Effective, professional layout</li>
                  <li>Search engine optimization (SEO)</li>
                  <li>Social media sharing</li>
                  <li>E-commerce ready</li>
                </ul>), image: "business.png"
              }))],

              ["Customized", new Map(Object.entries({
                primaryInfo: (<ul>
                  <li>You provide the spec. We build it</li>
                  <li>Connect to your-domain.com</li>
                  <li>High-caliber feature set</li>
                </ul>), image: "paintbrush.png"
              }))],

              ["Performant", new Map(Object.entries({
                primaryInfo: (<ul>
                  <li>Quick loading across the globe</li>
                  <li>Looks great on mobile and desktop</li>
                  <li>Accessible to keyboard users and screen readers</li>
                  <li>Accessible on slow connections</li>
                </ul>), image: "wheelchair.png"
              }))],

              ["No nonsense", new Map(Object.entries({
                primaryInfo: (<ul>
                  <li>No ads</li>
                  <li>No lock-in</li>
                  <li>Full privacy</li>
                  <li>You own your content</li>
                </ul>), image: "kicking.png"
              }))],

            ])} />

          </>)
        }))
      }))],

      ["Getting started", new Map(Object.entries({
        link: "/getting-started#TabContent",
        primaryInfo: (<>
          <p><i>
            Check out our <TabLink to="/services">services and pricing</TabLink> or
            just hop right into a spec.
            Take a look at our <TabLink to="/getting-started">simple instructions</TabLink> to
            see just how easy it is to specify a website.
            As always, feel free to <TabLink to="/contact">contact us</TabLink> with any questions.
          </i></p>
        </>), image: "sprout.png",
      }))],


    ])} />

    <p>
      This project is supported by&nbsp;
      <a href="https://opensocialresources.com/" target="_blank" rel="noreferrer">
        Open Social Resources
      </a>.
      We're determined to make the world great and thrive doing it.
    </p>

  </>),
}

/*
 * Services tab
 */

const Centered = styled.div`
  display: inline-grid;
  grid-template-columns: 100%;
  grid-row-gap: 10px;
  width: 100%;
  justify-items: center;
`

const ServicesTable = styled.div`
  display: grid;
  grid-template-columns: max-content repeat(3, 8em);
  grid-column-gap: 10px;
  grid-template-rows: min-content repeat(8, auto);
  grid-row-gap: 10px;
  margin-top: 10em;
  margin-bottom: 10px;
  justify-items: center;
`

const ServiceHeading = styled.div`
  transform: rotate(-45deg);
  overflow-wrap: null;
  white-space: nowrap;
  width: 0;
  margin-bottom: -15px;
`

const ServiceName = styled.h3`
  margin: 0;
  text-transform: none;
  letter-spacing: 0.1em;
  font-size: x-large;
  font-weight: bold;
`

const ServiceDescription = styled.span`
  padding-left: 1.5em;
`

const CheckboxContainer = styled.div`
  width: 2em;
  height: 2em;
`

const CheckedBox = props => (
  <CheckboxContainer>
    <DynamicImage src={"checked_box.png"} shouldShowBackground={false} />
  </CheckboxContainer>
)

const UncheckedBox = props => (
  <CheckboxContainer>
    <DynamicImage src={"unchecked_box.png"} shouldShowBackground={false} />
  </CheckboxContainer>
)

const CreationPriceGrid = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, min-content);
  align-items: start;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  justify-self: center;
  line-height: 100%;

  .dollar {
    font-size: xx-large;
  }

  .raised {
    font-size: small;
    line-height: 0.2em;
  }
`

const MonthlyPriceGrid = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(4, min-content);
  align-items: start;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  justify-self: center;
  line-height: 100%;

  .dollar {
    font-size: x-large;
  }

  .raised {
    font-size: small;
    line-height: 0.6em;
  }

  .period {
    margin-left: 5px;
    align-self: end;
  }
`

const CreationPrice = props => (
  <CreationPriceGrid>
    <div className={"raised"}>$</div>
    <div className={"dollar"}>{props.dollar}</div>
  </CreationPriceGrid>
)

const MonthlyRate = props => (
  <MonthlyPriceGrid>
    <div className={"raised"}>$</div>
    <div className={"dollar"}>{props.dollar}</div>
    <div className={"raised"}>{props.cents}</div>
    <div className={"period"}>/mo</div>
  </MonthlyPriceGrid>
)

const PerkContainer = styled.div`
  display: inline-grid;
  justify-items: right;
  align-items: center;
  grid-template-columns: min-content min-content;
  column-gap: 10px;
  text-align: right;

  @media ${Breakpoints.smallOrLarger} {
    grid-template-columns: max-content min-content;
  }
`

const Perk = props => (
  <div style={{justifySelf: "right"}}>
    <Modal id={props.modalId} contentLabel={props.title}>
      {props.children}
    </Modal>
    <PerkContainer>
      <div>
        {props.title}
      </div>
      <ModalLink modalId={props.modalId}>
        <DynamicImage
          src="info_icon.png"
          alt={`${props.title} details`}
          containerStyle={{
            width: "1em",
            height: "1em",
          }}
          />
      </ModalLink>
    </PerkContainer>
  </div>
)

const servicesTab = {
  slug: "services",
  name: "Services",
  content: (<>
    <h2>Services</h2>
    <p>
      You provide the website spec using our&nbsp;
      <a href="https://website-template.opensocialresources.com/docs/website_spec.pdf" target="_blank" rel="noreferrer">straightforward guide</a>.
      We create the site and publish it for you.
      Choose from one of our common plans or customize your own.
    </p>
    <ServicesTable>
      <div />
      <ServiceHeading>
        <ServiceName>
          Brick & Mortar
        </ServiceName>
        <ServiceDescription>
          Brick & mortar establishment
        </ServiceDescription>
      </ServiceHeading>
      <ServiceHeading>
        <ServiceName>
          E-Commerce
        </ServiceName>
        <ServiceDescription>
          Online business
        </ServiceDescription>
      </ServiceHeading>
      <ServiceHeading>
        <ServiceName>
          Deluxe & Custom
        </ServiceName>
        <ServiceDescription>
          Extra content, customization
        </ServiceDescription>
      </ServiceHeading>
      <div />
      <Centered>
        <CreationPrice dollar={"299"} />
        <MonthlyRate dollar={"19"} cents={"99"} />
      </Centered>
      <Centered>
        <CreationPrice dollar={"399"} />
        <MonthlyRate dollar={"19"} cents={"99"} />
      </Centered>
      <Centered>
        <CreationPrice dollar={"100"} />
        <div style={{marginTop: "-8px"}}>Starting price</div>
      </Centered>
      <Perk
        title={"Feature upgrades"}
        modalId={"feature-upgrades"}
      ><p>
        We regularly add new features and upgrade existing ones.
        We automatically upgrade your website to utilize
        the best feature set.
      </p></Perk>
      <CheckedBox />
      <CheckedBox />
      <CheckedBox />
      <Perk
        title={"Security updates"}
        modalId={"security-updates"}
      ><p>
        We keep our security and best-practices up-to-date.
        We automatically apply these updates to your website.
      </p></Perk>
      <CheckedBox />
      <CheckedBox />
      <CheckedBox />
      <Perk
        title={"Encrypted data"}
        modalId={"encrypted-data"}
      ><p>
        Data coming to and from your website is encrypted using SSL.
        Additionally, this ensures your users don't see a scary message
        about your website being insecure.
      </p></Perk>
      <CheckedBox />
      <CheckedBox />
      <CheckedBox />
      <Perk
        title={"Custom email"}
        modalId={"custom-email"}
      ><p>
        Get a custom email through Google.
      </p></Perk>
      <CheckedBox />
      <CheckedBox />
      <CheckedBox />
      <Perk
        title={"Online sales"}
        modalId={"online-sales"}
      ><p>
        Sell your products online.
        You have full control over your products using
        <a href="https://stripe.com/" target="_blank" rel="noreferrer">Stripe</a>.
        Or you can give us your product info and we'll
        send orders straight to your email.
      </p></Perk>
      <UncheckedBox />
      <CheckedBox />
      <CheckedBox />
      <Perk
        title={"International boost"}
        modalId={"international-boost"}
      ><p>
        Maximize your performance with international load balancing
        and content management.
        Your website is always accessible across the globe.
        This option ensures that your content is
        served closer to your users, no matter their location.
        Like a physical package,
        your users will get your website faster
        from across town than across the ocean.
      </p></Perk>
      <UncheckedBox />
      <CheckedBox />
      <CheckedBox />
    </ServicesTable>
    <p>
      To learn more about any of our services,
      please&nbsp;
      <TabLink to="/contact">contact us</TabLink>.
    </p>
    <p>
      Any work that we do and any project that we work on must adhere to our&nbsp;
      <a href="https://gitlab.com/momosa/website_template/-/blob/master/CODE_OF_CONDUCT.md">
        Code of Conduct 
      </a>.
    </p>
    <table className={"notes"}>
      <caption><h2>Notes</h2></caption>
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}><b>Sizing</b>:</td>
          <td>
            Websites that are large or complex
            may require Deluxe & Custom options.
            That can happen if the text or images require editing,
            or if you have a whole lot of content to include.
            Don't worry&mdash;we don't like surprises any more than you do.
            We'll discuss it with you before starting any work.
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}><b>Hosting</b>:</td>
          <td>
            Hosting prices are for normal usage,
            up to 100,000 site views per month for a typical website.
            We'll adjust the monthly rate if you require more traffic.
          </td>
        </tr>
      </tbody>
    </table>
  </>),
}

const gettingStartedTab = {
  slug: "getting-started",
  name: "Getting started",
  content: (<>
    <h2>Getting started</h2>
    <p>
      To see what you need to create your own website, take a look at our website spec form:
    </p>
    <p><a href="https://website-template.opensocialresources.com/docs/website_spec.pdf" target="_blank" rel="noreferrer">
      Website spec form</a></p>

    <Youtube
      title="Website spec walkthrough"
      src="https://www.youtube.com/embed/6you0V2sb-4"
    />

    <p>
      Do you want us to publish your website for you?
      If so, please&nbsp;
      <TabLink to="/contact">contact us</TabLink>.
    </p>
    <p>
      Do you want to use the Website Template Project as a basis for you own website?
      Check out&nbsp;
      <a href="https://gitlab.com/momosa/website_template">our repo</a>.
    </p>
  </>),
}

const contactTab = {
  slug: "contact",
  name: "Contact",
  content: (<>
    <h2>Contact us</h2>
    <CustomForm
      id="my-form"
      action={"https://formspree.io/mqkydkee"}
      debug={process.env.GATSBY_ENVIRONMENT !== "production"}
      fields={[
        new Map(Object.entries({
          fieldName: "name",
          required: true,
          label: "Name",
          input: "text",
        })),
        new Map(Object.entries({
          fieldName: "email",
          required: true,
          label: "Email",
          input: "email",
        })),
        new Map(Object.entries({
          fieldName: "message",
          required: true,
          label: "Message",
          input: "textarea",
        })),
      ]}
      formFields={["name", "email", "message"]}
    />
  </>),
}

const tabs = [
  featuresTab,
  servicesTab,
  gettingStartedTab,
  contactTab,
]

/*******************************************************************************
 *
 * Business Splash
 *
 * Defined here to handle a lot of customization.
 * Consider relocating to components once we have themes.
 *
 ******************************************************************************/

const Separator = styled.hr`
  border-top: 1px solid lightgray;
`

// Need to move the id marker up so navigation is visible
// Use only if bottom tab list present
const TabContentPlaceholder = styled.div`
  position: relative;
  top: -3em;
`
const StyledBusinessIdentifiers = styled.div`
  width: 100%;
  display: inline-grid;
  box-sizing: border-box;
  grid-template-columns: ${props => props.shouldUseIconLogo ?
    'min-content auto' :
    'auto'
  };
  column-gap: 15px;
  justify-content: start;
  align-items: center;
  grid-template-areas: ${props => props.shouldUseIconLogo ?
    '"icon title" "description description"' :
    '"title" "description"'
  };

  @media ${Breakpoints.smallOrLarger} {
    padding: 15px;
    padding-top: 0;
    row-gap: 7px;
    grid-template-columns: min-content auto min-content;
    grid-template-areas:
      "icon        title"
      "icon        description";
  }
`

const TitleContainer = styled.div`
  min-width: 60vw;
  grid-area: title;
`

const IconContainer = styled.div`
  grid-area: icon;
  height: 70px;
  width: 70px;

  @media ${Breakpoints.smallOrLarger} {
    height: 120px;
    width: 120px;
    padding-right: 15px;
    margin-bottom: 8px;
  }
`

const SlideshowContainer = styled.div`
  background-color: var(--backgroundColor);
  @media ${Breakpoints.smallOrLarger} {
    margin: auto;
    max-width: 90%;
  }
  @media ${Breakpoints.largeOrLarger} {
    max-width: 80%;
  }
`

const slideLeft = keyframes`
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
`

const DescriptionContainer = styled.p`
  grid-area: description;
  font-style: italic;
  font-size: larger;
  animation: ${slideLeft} 1s ease-out;

  @media ${Breakpoints.smallOrLarger} {
    margin: 0;
    vertical-align: middle;
  }
`

function BusinessIdentifiers(props) {
  const siteMetadata = useSiteMetadata()
  return (
    <StyledBusinessIdentifiers
      shouldUseIconLogo={true}
      data-qa={props["data-qa"] || "BusinessIdentifiers"}
    >

      <IconContainer data-qa={"BusinessIcon"}>
        <DynamicImage
          src="icon.png"
          alt="Business icon"
          data-qa="BusinessIcon"
          shouldShowBackground={false}
        />
      </IconContainer>

      <TitleContainer>
      {/*
        <h1>
          <DynamicImage
            src="banner_logo.png"
            alt={siteMetadata.title}
            data-qa="BusinessBanner"
            shouldShowBackground={false}
          />
        </h1>
      */}
        <Title
          text={siteMetadata.title}
          data-qa={"BusinessTitle"}
        />
      </TitleContainer>

      <DescriptionContainer data-qa={"BusinessDescription"}>
        {siteMetadata.description}
      </DescriptionContainer>

    </StyledBusinessIdentifiers>

  )
}

function TabPageSplash(props) {
  const userPreferences = useUserPreferences()

  return (<>
    <BusinessIdentifiers />
    <SlideshowContainer>
      <SlideShow
        images={userPreferences.splashImages}
        data-qa={"SplashImages"}
      />
    </SlideshowContainer>


    {/* Ensure bottom tab list is visible when switching tabs
        Only use with bottom tab list
    */}
    <TabContentPlaceholder id="TabContent" />

    <div role={"navigation"}>
      <TabList
        activeTab={props.activeTab}
        tabs={tabs}
      />
    </div>

    <Separator />
  </>)
}

export default function getUserContent() {
  return {
    footer,
    tabs,
    BusinessIdentifiers,
    TabPageSplash,
  }
}
