/* Youtube
 */

import React from "react"
import styled from "styled-components"

const StyledVideo = styled.iframe`
  display: block;
  margin: auto;
  border: 0;
  width: min(90%, 600px);
  height: min(50vh, 450px);
`

export default function Youtube(props) {
  if (!props.src) {
    throw ReferenceError("prop 'src' not defined")
  }
  return (
    <StyledVideo {...props} allowFullScreen ></StyledVideo>
  )
}

Youtube.defaultProps = {
  title: "Embedded Youtube",
  frameBorder: "0",
  allow: [
    "accelerometer",
    "autoplay",
    "clipboard-write",
    "encrypted-media",
    "gyroscope",
    "picture-in-picture",
  ].join("; "),
  "aria-hidden": "false",
  "tab-index": "0",
  loading: "lazy",
  scrolling: "no",
  "data-qa": "YoutubeVideo",
}
